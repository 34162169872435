import React, { Component } from "react";
import axios from "axios";
import validator from 'validator';
import swal from "sweetalert";

const AnalyticsCode = 'dtlgadttq424t3u3o3z1zuq08';

const getRegistrationToken = () => {
    const search = new URLSearchParams(window.location.search);
    const registrationToken = search.get("registrationToken");
    return registrationToken;
};

const getProductCode = () => {
    const search = new URLSearchParams(window.location.search);
    const productCode = search.get("pcode");
        if(productCode === AnalyticsCode){
            var productTitle = 'Analytics Shift';
            return productTitle;
        }
};

const getCID = () => {
    const search = new URLSearchParams(window.location.search);
    const custId = search.get("cid");
            return custId;
};

class Registration extends Component {
    constructor(props) {
        super(props);
        //const product = getProductName();
        const productCode = getProductCode();
        console.log(productCode);
        const customerIdentifier = getCID();
        console.log(customerIdentifier);
        const registrationToken = getRegistrationToken();
        this.state = {
            productCode,
            customerIdentifier,
            registrationToken,
            firstname: "",
            lastname: "",
            email: "",
            city: "",
            country: "",
            phone: "",
            firstnameError: false,
            lastnameError: false,
            emailError: false,
            cityError: false,
            countryError: false,
            phoneError: false,
        };
        this.validateForm = this.validateForm.bind(this);
        this.changeInput = this.changeInput.bind(this);
    }
    validateForm() {
        const firstname = this.state.firstname;
        const lastname = this.state.lastname;
        const email = this.state.email;
        const city = this.state.city;
        const country = this.state.country;
        const phone = this.state.phone;

        const registrationPayload = {
            firstname: firstname,
            lastname: lastname,
            email: email,
            city: city,
            country: country,
            phone: phone,
            registrationToken: this.state.registrationToken,
            productCode: this.state.productCode,
            customerIdentifier: this.state.customerIdentifier
        };

        if (
            firstname &&
            lastname &&
            email &&
            city &&
            country &&
            phone &&
            phone.length === 10 &&
            validator.isEmail(email) === true &&
            validator.isMobilePhone(phone) === true &&
            validator.isAlpha(firstname) === true &&
            validator.isAlpha(lastname) === true
        ) {
            this.setState({
                firstnameError: false,
                firstname: "",
                fnameMessage: "",
                lastnameError: false,
                lastname: "",
                lnameMessage: "",
                emailError: false,
                email: "",
                emailMessage: "",
                cityError: false,
                city: "",
                cityMessage: "",
                countryError: false,
                country: "",
                countryMessage: "",
                phoneError: false,
                phone: "",
                phoneMessage: ""
            });
            console.log("Payloads",registrationPayload)
            //Post to server
            axios
                .put("https://tapestry-threads-prod-service.tapestrykpi.com/marketPlace/register", registrationPayload)
                //.put("http://localhost:9797/marketPlace/register", registrationPayload)
                .then((response) => {
                    swal("Thank You", "Our product team will contact you within 2 business days", "success")
                })
                .catch((error) => {
                    swal("Oops...", "Something went wrong!", "error");
                });
        } else {
            if (firstname === "") {
                this.setState({ firstnameError: true, fnameMessage: "*Field Required" })
            } else if (validator.isAlpha(firstname) === false) {
                this.setState({ firstnameError: true, fnameMessage: "*Numeric value not allowed" })
            } if (lastname === "") {
                this.setState({ lastnameError: true, lnameMessage: "*Field Required" })
            } else if (validator.isAlpha(lastname) === false) {
                this.setState({ lastnameError: true, lnameMessage: "*Numeric value not allowed" })
            } if (city === "") {
                this.setState({ cityError: true, cityMessage: "*Field Required" })
            } if (country === "") {
                this.setState({ countryError: true, countryMessage: "*Field Required" })
            } if (email === "" || validator.isEmail(email) === false) {
                this.setState({ emailError: true, emailMessage: "*Please enter valid email" })
            } if (phone === "" || validator.isMobilePhone(phone) === false || phone.length !== 10) {
                this.setState({ phoneError: true, phoneMessage: "*Please enter valid phone number" })
            }
        }
    }

    changeInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        const firstname = this.state.firstname;
        const lastname = this.state.lastname;
        const email = this.state.email;
        const city = this.state.city;
        const country = this.state.country;
        const phone = this.state.phone;
        this.setState({ [name]: value });
        if (firstname !== "") {
            this.setState({ firstnameError: false, fnameMessage: "" })
        } if (lastname !== "") {
            this.setState({ lastnameError: false, lnameMessage: "" })
        } if (email !== "") {
            this.setState({ emailError: false, emailMessage: "" })
        } if (city !== "") {
            this.setState({ cityError: false, cityMessage: "" })
        } if (country !== "") {
            this.setState({ countryError: false, countryMessage: "" })
        } if (phone !== "") {
            this.setState({ phoneError: false, phoneMessage: "" })
        }
    }

    render() {
        let formHide = {
            visibility: this.state.productCode ? 'visible' : 'hidden'
        }
        let formUnhide = {
            visibility: !this.state.productCode || !this.state.registrationToken ? 'visible' : 'hidden',
            marginLeft: '30%',
            whiteSpace: 'nowrap',
            color: 'red'
        }
        return (
            <div>
                <div className="container register">
                    <div className="row">
                        <div className="col-md-3 register-left">
                            <img src={require("../assets/softcryliclogo.png")} alt="" style={{ width: '100px', height: '100px' }} />
                            <h4 style={formHide}>Thank you for your Interest in {this.state.productCode}!</h4>
                        </div>
                        <div className="col-md-9 register-right">
                            <div
                                className="tab-pane fade show active"
                                id="home"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                            >
                                <h3 className="register-heading" style={formHide}>{this.state.productCode} Registration</h3>
                                <div className="row register-form" style={formHide}>
                                    <div className="form-group" >
                                        <h4 className="col-md-4 error-heading" style={formUnhide}>Sorry, An Error Has Occured.</h4>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{
                                                    border: this.state.firstnameError
                                                        ? "1px solid red"
                                                        : "",
                                                }}
                                                placeholder="First Name *"
                                                value={this.state.firstname}
                                                name="firstname"
                                                onChange={(e) => this.changeInput(e)}
                                            />
                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                {this.state.fnameMessage}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{
                                                    border: this.state.lastnameError
                                                        ? "1px solid red"
                                                        : "",
                                                }}
                                                placeholder="Last Name *"
                                                value={this.state.lastname}
                                                name="lastname"
                                                onChange={(e) => this.changeInput(e)}
                                            />
                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                {this.state.lnameMessage}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{
                                                    border: this.state.cityError ? "1px solid red" : "",
                                                }}
                                                placeholder="City *"
                                                value={this.state.city}
                                                name="city"
                                                onChange={(e) => this.changeInput(e)}
                                            />
                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                {this.state.cityMessage}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                style={{
                                                    border: this.state.emailError ? "1px solid red" : "",
                                                }}
                                                placeholder="Your Email *"
                                                value={this.state.email}
                                                name="email"
                                                onChange={(e) => this.changeInput(e)}
                                            />
                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                {this.state.emailMessage}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                minLength="10"
                                                maxLength="10"
                                                name="txtEmpPhone"
                                                className="form-control"
                                                style={{
                                                    border: this.state.phoneError ? "1px solid red" : "",
                                                }}
                                                placeholder="Phone Number *"
                                                value={this.state.phone}
                                                name="phone"
                                                onChange={(e) => this.changeInput(e)}
                                            />
                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                {this.state.phoneMessage}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                pattern="[0-9]*"
                                                type="text"
                                                className="form-control"
                                                style={{
                                                    border: this.state.countryError ? "1px solid red" : "",
                                                }}
                                                placeholder="Country *"
                                                value={this.state.country}
                                                name="country"
                                                onChange={(e) => this.changeInput(e)}
                                            />
                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                {this.state.countryMessage}
                                            </span>
                                        </div>
                                        <input
                                            type="submit"
                                            className="btnRegister"
                                            value="Register"
                                            onClick={this.validateForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registration;